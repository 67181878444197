
<template>
  <base-section
    id="news"
    space="72"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <news-card
            v-for="(article, i) in articles"
            :key="i"
            :divider="i + 1 < articles.length"
            class="mb-12"
            v-bind="article"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <news-categories />

          <news-tags />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    components: {
      NewsCard: () => import('@/components/news/Card'),
      NewsCategories: () => import('@/components/news/Categories'),
      NewsSearch: () => import('@/components/news/Search'),
      NewsTags: () => import('@/components/news/Tags'),
    },

    data: () => ({}),

    computed: {
      articles () {
        return this.$store.getters['news/list']
      },
    },

    mounted () {
      this.fetchNews()
    },

    methods: {
      fetchNews () {
        return this.$store.dispatch('news/getNews')
      },
    },
  }
</script>
